import React from 'react';
import './article.css';
import { Link } from "react-router-dom";
import {useState} from 'react';


const Article = ({imgUrl, type, title, URL}) => {

  const [isHovering, setIsHovering] = useState(false);
  
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <>
    <div className='view-desktop'>
      <Link to={URL}>
        <div className="site__blog-container_article"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        >
          <div className="site__blog-container_article-image">
          <img src={imgUrl} alt="blog image" />
          </div>
          <div className="site__blog-container_article-content">
            <div>
              <p>{isHovering && type}</p>
              <h3>{isHovering && title}</h3>
              
            </div>
          </div>
        </div>
      </Link>
    </div>

    <div className='view-mobile'>
      <Link to={URL}>
        <div className="site__blog-container_article">
          <div className="site__blog-container_article-image">
          <img src={imgUrl} alt="blog image" />
          </div>
          <div className="site__blog-container_article-content">
            <div>
              <p>{type}</p>
              <h3>{title}</h3>
              
            </div>
          </div>
        </div>
      </Link>
    </div>

    </>
  )
}

export default Article