import React from 'react';
import { Photo } from '../../components';
import { photo01, photo02, photo06, photo09, photo10, photo11, photo12, photo13} from'./imports';
import './recent.css';


const Recent = () => {
 
  return (
    <>
    <div className="site__gallery section__padding" id="recent_work">
    
      <div className="site__gallery-container view-desktop">
        
        <div className="site__gallery-container_groupC">
            <Photo num="01" imgUrl={photo10} title="RevoltWear" type="Visual Identity"  URL="/RevoltWear"/>
            <Photo num="02" imgUrl={photo12} title="ArtiTicket" type="UX Design"  URL="/Artiticket"/>
            <Photo num="03" imgUrl={photo13} title="Coming Soon" type=""  URL=""/>
        </div>
        <div className="site__gallery-container_groupB">
            <Photo num="04" imgUrl={photo02} title="Breath of the Wild Icons" type="Iconography"  URL="/Breath-of-the-Wild-icons"/>
            <Photo num="05" imgUrl={photo01} title="Dark Star" type="UI Design" URL="/Dark-Star" />
            <Photo num="06" imgUrl={photo06} title="Infinitea" type="Product Design" URL="/Infinitea"/>
            <Photo num="07" imgUrl={photo09} title="Minus The" type="Logo Design" URL="/Minus-The"/>
            {/* <Photo num="06" imgUrl={photo08} title="Lettie & Co" type="Logo Design" URL="https://www.behance.net/gallery/167343099/Lettie-Co"/> */}
            
        </div>
      </div>

      <div className="site__gallery-container view-mobile">
        
        <div className="site__gallery-container_groupB">
          <Photo num="01" imgUrl={photo11} title="RevoltWear" type="Visual Identity"  URL="/RevoltWear"/>
          <Photo num="02" imgUrl={photo12} title="ArtiTicket" type="UX Design"  URL="/Artiticket"/>
          <Photo num="03" imgUrl={photo13} title="Coming Soon" type=""  URL=""/>
          <Photo num="04" imgUrl={photo02} title="Breath of the Wild Icons" type="Iconography"  URL="/Breath-of-the-Wild-icons"/>
          <Photo num="05" imgUrl={photo01} title="Dark Star" type="UI Design" URL="/Dark-Star" />
          <Photo num="03" imgUrl={photo06} title="Infinitea" type="Product Design" URL="/Infinitea"/>
          <Photo num="02" imgUrl={photo09} title="Minus The" type="Logo Design" URL="/Minus-The"/>
          {/* <Photo num="06" imgUrl={photo08} title="Lettie & Co" type="Logo Design" URL="https://www.behance.net/gallery/167343099/Lettie-Co"/> */}
        </div>
       
      </div>


    </div>
        </>
  )
}

export default Recent