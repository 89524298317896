import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ScrollToTop from './../components/ScrollToTop';
import  './pages.css';
import  { Navbar } from './../components';
import { Social, MasonryGD } from './../containers';

import Gallery from '../containers/gallery/Gallery';
// import { proj3_imgURL1, proj3_imgURL2, proj3_imgURL4, proj3_imgURL5, proj3_imgURL6, proj3_imgURL7 } from'./imports';

const GD = () => {


  return (
<>
  <ScrollToTop />
  
  <div className='site__header-img-BG-GD-pages'>
    <Navbar />
    <div className='site__pages' id="topPage" />
      <div className="site__header-pages section__padding">
        <div className='site__2col'>
          <div className="site__header-content" >
            <h1 className="solid__text">Designs that impress.</h1>

              <div className='site__intro-containter'>
                {/* <div className='site__intro-content'>
                  <h5>So much so, they invite a standing ovation from your retinas.</h5>
                </div> */}
              </div>
          </div>
          {/* <div className="site__header-image">
            <img src={photo} alt="graphic design img" />
          </div> */}
        </div>
      </div>
    {/* </div> */}
  </div>


  {/* <div className='site__2col section__padding' id="site__page-ux-remove-mobile">
    <div className='site__2col-groupA'>
      <p>
      Content optimized for social.  
      </p>
    </div>
    <div className='site__2col-groupB'>
      <p>
      01 <code> &#8212;&#8212; </code> brand identity
              02 <code> &#8212;&#8212; </code> brand identity<br />
              03 <code> &#8212;&#8212; </code> brand identity
              04 <code> &#8212;&#8212; </code> brand identity<br />
              05 <code> &#8212;&#8212; </code> brand identity
              06 <code> &#8212;&#8212; </code> brand identity<br />
      </p>
    </div>
  </div> */}

    <Gallery />

    {/* <div className='site__masonry-content section__padding'>
        <h2>Essence of the scenes.</h2>
        <h6>An exclusive preview</h6>
      </div>
      <MasonryGD /> */}
    

</>
  )
}

export default GD