import React from 'react';
import './photo.css';
import { Link } from "react-router-dom";
import {useState} from 'react';

const Photo = ({imgUrl, type, title, URL, num}) => {

  // const [isHovering, setIsHovering] = useState(false);
  
  // const handleMouseOver = () => {
  //   setIsHovering(true);
  // };

  // const handleMouseOut = () => {
  //   setIsHovering(false);
  // };

  return (
    <>
    

    <div>
      <Link to={URL}>
        <div className="site__gallery-container_photo">
          <div className="site__gallery-container_photo-image">
            <p>{num}</p>
          <img src={imgUrl} alt="gallery image" />
          </div>
          <div className="site__gallery-container_photo-content">
             <h5>{title}</h5>
              <p>{type}</p>
          </div>
        </div>
      </Link>
    </div>

    
    </>
  )
}

export default Photo