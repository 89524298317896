import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import './masonryGrid.css';

const MasonryGrid = ({img1, img2, img3, img4, img5, img6, img7, img8, img9, img10}) => {
  const images = [
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10
  ];


  return (
    <div>
        <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >

      <Masonry columnsCount={4} gutter="10px">
        {images.map((image, i) => (
          <img
            key={i}
            src={image}
            style={{ width: "100%", display: "block" }}
          />
        ))}
      </Masonry>

      </ResponsiveMasonry>
    </div>
  );
}
export default MasonryGrid;
