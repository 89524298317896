import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import './footer.css';


const Footer = () => {
  return (
    <div className="site__footer section__padding">
      <div className="site__footer-heading site__2col">
        <div className='site__2col-groupA'>
          <h3>Let's chat
            <div className='svg__container basline'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
               <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
              </svg>
            </div>
          </h3>
        </div>
        <div className='site__2col-groupB'>
          <div className='project-btn'>
            <a className="links-footer" href="mailto:siemenstaylor@gmail.com">Slide into my DMs</a>
          </div>
        </div>
      </div>

      {/* <div className="site__footer-btn">
        <p><a href="mailto:siemenstaylor@gmail.com">Email</a></p>
      </div> */}

      <div className="site__footer-links">
        
        <div className='site__footer-links_div'>
          <div className="site__footer-links_logo">
            {/* <img src={logo} alt="logo" /> */}
            <h1>T — S</h1>
          </div>

          <div className=' site__navbar-sign'>
              <a href="https://cara.app/tayyy/all"><i class="fa-lg fa-solid fa-copyright"></i></a>
              <a href="https://www.artstation.com/tayyy"><i class="fa-lg fa-brands fa-artstation"></i></a>
              <a href="https://www.behance.net/itstayyy"><i class="fa-lg fa-brands fa-behance"></i></a>
              <a href="https://giphy.com/itstayyy"><i class="fa-lg fa-regular fa-image"></i></a>
              <a href="https://www.instagram.com/taylormade.jpeg"><i class="fa-lg fa-brands fa-instagram"></i></a>
              {/* <a href="https://www.linkedin.com/in/taylorsiemens"><i class="fa-lg fa-brands fa-linkedin-in"></i></a> */}
              {/* <a href="https://twitter.com/taylormade_jpeg"><i class="fa-lg fa-brands fa-twitter"></i></a> */}


          </div>
        </div>
       

        <div className="site__footer-links_div">
          <h6 id="section">Explore</h6>
          <h6><a className="links-footer" href="/">Home</a></h6>
          <Link to="/about" className="links-nav"><h6>About</h6></Link>
          <Link to="/ux-ui" className="links-nav"><h6>UI/UX</h6></Link>
          <Link to="/gd" className="links-nav"><h6>Graphic Design</h6></Link>
          <h6><a className="links-footer" href="https://www.redbubble.com/people/its-tayyy/shop?asc=u">Shop</a></h6>
        </div>
          
          <div className="site__footer-links_div">
            {/* <a className="links-footer" href="https://contra.com/taylorsiemens"><img style={{"height" : "100%", "width" : "65%"}} src="https://contra.com/static/embed/media/hiremeoncontra-dark.png" alt="Contra hire me badge"/></a> */}
            <a className="links-footer" id="kofi" href="https://ko-fi.com/tayyy"><img style={{"height" : "100%", "width" : "65%",  "margin-top" : "1rem"}} src="https://storage.ko-fi.com/cdn/kofi2.png?v=3" alt="Ko-fi buy a coffee badge"/></a>
          </div>
      </div>

      <div className="site__footer-copyright">
        <p>© 2019 - 2023 Taylor Siemens. All rights reserved.</p>
      </div>
     
    </div>
  )
}

export default Footer