import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ScrollToTop from './../components/ScrollToTop';
import  './projects.css';
import  { Navbar } from './../components';

import Blog from '../containers/blog/Blog';
import { proj3_imgURL1, proj3_imgURL2, proj3_imgURL4, proj3_imgURL5, proj3_imgURL6,
    proj3_imgURL8, proj3_imgURL9, proj3_imgURL10 } from'./imports';

const BOTWicons = () => {


  return (
<>
    <Navbar />
    <ScrollToTop />
    <div className='site__project' id="topPage">
    <div className="site__header section__padding">
     <div className="site__project-header-content" >
        <h1 className="solid__text">Breath of the wild Icons</h1>
        <div className='project-btn'>
            <Link to="/Breath-of-the-Wild-icons" className="site__project-link">Explore UI Art</Link>
        </div>
     </div>

    </div>

   
    <div className="site__tldr section__padding">
        <h3>TLDR;</h3>
        <div className="site__tldr-groupA">
            <p><strong>Deliverables:</strong> Game Icons</p>
            <p><strong>Project:</strong> Personal project to practice illustration and creating icons used in video games</p>
            <p><strong>My Responsibilities:</strong> Illustrating Breath of the Wild icons</p>
            <p><strong>The Challenge:</strong> Re-creating recognizable icons from a respected video game</p>
        </div>

        <div className="site__tldr-groupB">
            <p><strong>Completed:</strong> October 2022</p>
            <p><strong>Artist:</strong> Me, myself and I (Taylor)</p>
            <p><strong>Applications:</strong> Procreate</p>
            <p><strong>Published On: </strong><a className="site__project-link" href="https://www.artstation.com/artwork/2qE0ZA">Artstation </a> </p>        
            <p><strong>UI Art: </strong><Link to="/Breath-of-the-Wild-icons" className="site__project-link">Explore UI Art</Link></p>
        </div>
    </div>

    <div className='site__casestudy section__padding'>
        <div className='site__casestudy-groupA'>
            <div className="site__casestudy-image">
                <img src={proj3_imgURL1} alt="casestudy" />
            </div>
            <div className='site__casestudy-content'>
            <h3>The Purpose</h3>
                <p>I am a great admirer of The Legend of Zelda (TLOZ) franchise, and I aspired to improve my artistic skills to emulate the unique style of the acclaimed game Breath of the Wild (BOTW).</p>
            </div>
        </div>

        <div className='site__casestudy-groupB'>
            <div className="site__casestudy-image">
                <img src={proj3_imgURL2} alt="casestudy" />
            </div>
            <div className='site__casestudy-content'>
                <h3>The Challenge</h3>
                <p>Utilizing my current artistic skills and techniques, my goal was to replicate the beloved icons from a revered series with utmost resemblance, if not complete accuracy.</p>
            </div>
        </div>
    </div>

    

    <div className='site__UX-Design section__padding'>

        <div className='snapshot-img'>
             <img src={proj3_imgURL4} alt="snapshot" />
             <img src={proj3_imgURL5} alt="snapshot" />
        </div>

        <div className='site__UX-Design-content'>
                <h3>First Task</h3>
                <p>My initial step was to select the specific icons that would best highlight my artistic capabilities. 
                    I opted to feature these Key Items in my artwork, as each icon posed a distinct level of complexity 
                    and had possibly received less attention from other artists compared to the commonly depicted food, weapons, and armor.</p>
        </div>

        <div className='snapshot-img'>
             <img src={proj3_imgURL8} alt="snapshot" />
        </div>

        <div className='site__UX-Design-content'>
                <h3>Process Breakdown & Inspiration</h3>
                <p> As a passionate devotee of the franchise, I draw a great deal of inspiration from all the Zelda games 
                    for my user interface and illustration projects. Aspired to improve my artistic skills and Iconography 
                    quality, I decided to emulate the unique style of the acclaimed game The Legend of Zelda, Breath of the 
                    Wild (BOTW).</p>


                <p>My initial step was to select the specific icons that would best highlight my artistic capabilities. I opted 
                    to feature these Key Items in my artwork, as each icon posed a distinct level of complexity and had possibly 
                    received less attention from other artists compared to the commonly depicted food, weapons, and armor.</p>

        </div>

        <div className='site__2col'>
            <div className='site__2col-groupA'>
                <img src={proj3_imgURL9} alt="snapshot" />
            </div>
            <div className='site__2col-groupB'>
            <img src={proj3_imgURL10} alt="snapshot" />
            </div>
        </div>

    </div>

    <div className='site__UI-Design section__padding'>

        <div className='snapshot-img'>
             <img src={proj3_imgURL6} alt="snapshot" />
        </div>

        <div className='site__UI-Design-content'>
                <h3>Outcomes</h3>
                <p>Overall, I am highly content with the end result of each icon, 
                    as I believe they accurately capture the essence of their respective in-game counterparts. 
                    To enhance my comprehension of the intricate detailing and intricate design that goes into each icon, 
                    I intend to a) pursue the creation of additional BOTW icons and b) develop my own original icons.</p>
        </div>

        <div className='site__back-to-top'>
            <Link to="#topPage" className="links-nav">Back to top</Link>
        </div>

    </div>

    

    </div>

    <div className='site__blog-heading'>
    <h1>Discover more.</h1>
    </div>
  
    <Blog />
</>
  )
}

export default BOTWicons