import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ScrollToTop from '../components/ScrollToTop';
import  './projects.css';
import  { Navbar } from '../components';

import Gallery from '../containers/gallery/Gallery';
import { proj7_imgURL1, proj7_imgURL2, proj7_imgURL3, proj7_imgURL4,
    proj7_imgURL5, proj7_imgURL6, } from'./imports';
import {proj7_HimgURL1, proj7_HimgURL2, proj7_HimgURL3, proj7_HimgURL4, proj7_HimgURL5} from './hImgs-imports';

const Infinitea = () => {


  return (
<>
    <Navbar />
    <ScrollToTop />
    <div className='site__project' id="topPage">
        <div className="site__project-header-imgCollage section__padding">
            <div className='site__project-header-imgCollage-groupA'>
                <img src={proj7_HimgURL1} alt="Close-up of the award-winning tea-infused cider flavour: Strawberry Kiwi" />
            </div>
            <div className='site__project-header-imgCollage-groupB'>
                 <img src={proj7_HimgURL2} alt="Looped animation of Infinitea Tropical Green Tea" />
            </div>
            <div className='site__project-header-imgCollage-groupC'>
                <img src={proj7_HimgURL4} alt="All Canadian Wine Championships, Best of category, double gold badge" />
                <img src={proj7_HimgURL3} alt="All Canadian Wine Championships, silver badge" />
            </div>
            <div className='site__project-header-imgCollage-groupD'>
                <img src={proj7_HimgURL5} alt="Close-up of the award-winning tea-infused cider flavour: Tropical Green Tea" />
            </div>
        </div>

   
        <div className="site__tldr section__padding">
            <div className="site__tldr-groupA" id="projectTitle-UI">
                <h1 className="solid__text-UI">Infinitea</h1>
            </div>
            <div className="site__tldr-groupA" id="project-UI">
                <p><strong>Deliverables:</strong> Label & Logo Design</p>
                <p><strong>Project:</strong> Design a label for a new tea-infused cider made by Wards Cider</p>
                <p><strong>My Responsibilities:</strong> As the lead designer, I collaborated with the Account Manager 
                to create an enticing label that pushed boundaries for a new cider product.</p>
                <p><strong>About:</strong> As the first series, the chosen constellations follow a 
                 of land dwelling animals and real constellations found in the skies above.</p>

            </div>

            <div className="site__tldr-groupC">
                <p><strong>Completed:</strong> Summer 2020</p>
                <p><strong>Applications:</strong> Illustrator, Photoshop</p>
                <p><strong>Award Winner:</strong>
                <a className="site__project-link" href="https://allcanadianwinechampionships.com/acwc-2021-results/#:~:text=InfiniTea%20Cider%20Tropical%20Green%20Tea%C2%A0"> Tropical Green Tea </a>and 
                <a className="site__project-link" href="https://allcanadianwinechampionships.com/acwc-2021-results/#:~:text=InfiniTea%20Cider%20Strawberry%20Kiwi"> Strawberry Kiwi</a></p>
                <p><strong>Purchase:</strong> Save-On Foods (BC)</p>

                
                {/* <p><strong>Published On: </strong><a className="site__project-link" href="https://www.artstation.com/artwork/2qE0ZA">Artstation </a> </p>   */}
            </div>
        </div>

        
        <div className='site__UX-Design section__padding'>

            <div className='snapshot-img'>
                 <img className="view-desktop" src={proj7_imgURL2} alt="Product mock-up of all four flavours in GIF format" />
                <img src={proj7_imgURL1} alt="Product mock-up of 3 tea-infused cider flavours" />
            </div>

            <div className='site__2col'>
                <div className='site__2col-groupA'>
                    <img src={proj7_imgURL6} alt="Award-winning tea-infused cider flavour: Strawberry Kiwi" />
                </div>
                <div className='site__2col-groupB'>
                    <img src={proj7_imgURL5} alt="Infinitea tea-infused cider flavour: Vanilla Honeybush" />
                </div>
            </div>

            <div className='site__2col'>
                <div className='site__2col-groupA'>
                    <img src={proj7_imgURL3} alt="Award-winning tea-infused cider flavour: Tropical Green Tea" />
                </div>
                <div className='site__2col-groupB'>
                    <img src={proj7_imgURL4} alt="Infinitea tea-infused cider flavour: Stone Fruit Hibiscus" />
                </div>
            </div>

            <div className='site__back-to-top'>
                <Link to="#topPage" className="links-nav">Back to top</Link>
            </div>

        </div>

    </div>

    <div className='site__blog-heading'>
    <h1>Discover more.</h1>
    </div>
  
    <Gallery />
</>
  )
}

export default Infinitea