import React from 'react';
import './feature.css';

const Feature = ({title, text, img, img2, img3, img4, img5, img6, img7}) => (
  <div className="site__features-container__feature">
    <div className="site__features-container__feature-title">
     {/* gradient bar above titles */}
      <div />
      <h5>{title}</h5>
    </div>
    <div className="site__features-container_feature-text">
      <h6>{text}</h6>
      <img id="scale-up" className="site__features-container_feature-image" src={img} alt="Adobe Creative Cloud" />
      <img id="scale-up" className="site__features-container_feature-image" src={img2} alt="Illustrator" />
      <img id="scale-up" className="site__features-container_feature-image" src={img3} alt="Indesign" />
      <img id="scale-up" className="site__features-container_feature-image" src={img4} alt="Figma" />
      <img id="scale-up" className="site__features-container_feature-image" src={img5} alt="Procreate" />
      <img id="scale-up" className="site__features-container_feature-image" src={img6} alt="FinalCut Pro" />
      <img id="scale-up" className="site__features-container_feature-image" src={img7} alt="Miro" />
    </div>
  </div>
);

export default Feature;