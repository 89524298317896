import React from 'react';
import { Feature } from '../../components';
import './features.css';

const featuresData = [
  {
    title: 'Google UX Design',
    text: '6-month online certification series focused on UX fundamentals and design thinking — 2023/2024, Google'
  },
  {
    title: 'UX/UI for Gaming',
    text: '7-week online certification program in gaming UX/UI fundamentals — 2022, ELVTR'
  },
  {
    title: 'Illustration from Imagination',
    text: '8-week online digitial painting program masterclass — 2022, Paintable.cc'
  },
  {
    title: 'Character Art School',
    text: 'Self-paced program covering the fundamental aspects of character drawing — 2022, Udemy'
  },
  {
    title: 'Adobe Design Achievement Awards',
    text: 'Semifinalist in an individual category, acknowledged for high skill level by experts — 2016, ADAA'
  },



]

const Features = () => (
  <div className="site__features section__padding" id="features">
   <div className="site__features-heading">
    <h3 className="solid__text">Awards & certifications.</h3>
    <p>An inventory filled with spells and cantrips that's built to impress.</p>
    </div>

    <div className='site__features-container'>
    {featuresData.map((item, index) => (
      <Feature title={item.title} text={item.text} key={item.title + index}/>
    ))}

    </div>
  </div>
);

export default Features