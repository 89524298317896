import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ScrollToTop from './../components/ScrollToTop';
import  './pages.css';
import  { Navbar, } from './../components';


import Blog from '../containers/blog/Blog';
import { Social, MasonryUX } from './../containers';


const UXUI = () => {


  return (
<>
  <ScrollToTop />
  
  <div className='site__header-img-BG-UXUI-pages'>
    <Navbar />
    <div className='site__pages' id="topPage">
      <div className="site__header-pages section__padding">
        <div className='site__2col'>
          <div className="site__header-content" >
              <h1 className="solid__text" id="uxpage-title">Empathize. Define. Ideate.  </h1>
              {/* <div className='site__intro-containter'>
                <div className='site__intro-content'>
                  <h5>Technology and design colliding in the most epic way possible.</h5>
                </div>
              </div> */}
          </div>
          {/* <div className="site__header-image">
            <img src={photo} alt="graphic design img" />
          </div> */}
        </div>
      </div>
    </div>
  </div>

  {/* <div className='site__2col section__padding' id="site__page-ux-remove-mobile">
    <div className='site__2col-groupA'>
      <p>Artistic Mastery <code>&#8212;&#8212;&#8212;&#8212;&#8212;</code> <em>a deep understanding of aesthetics, 
        and the ability to convey emotions, ideas, or concepts through artistic mediums</em></p>
    </div>
    <div className='site__2col-groupB'>
      <p>Scientific Ingenuity <code>&#8212;&#8212;&#8212;&#8212;&#8212;</code> <em>the ability to generate new ideas, 
      devise novel approaches, and make groundbreaking discoveries by applying scientific principles, methods, 
      and technologies</em></p>
    </div>
  </div> */}




      {/* <div className="site__blog-heading site__blog-container_groupA">
          <p>UI/UX Folio</p>
          <h1>Artistic mastery and scientific ingenuity.</h1>
      </div> */}
      <Blog />
      

      {/* <div className='site__masonry-content section__padding'>
        <h2>Essence of the scenes.</h2>
        <h6>An exclusive preview</h6>
      </div>
      <MasonryUX /> */}
    
</>
  )
}

export default UXUI