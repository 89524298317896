import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ScrollToTop from '../components/ScrollToTop';
import  './projects.css';
import  { Navbar } from '../components';

import Gallery from '../containers/gallery/Gallery';
import { proj8_imgURL1, proj8_imgURL2, proj8_imgURL3, proj8_imgURL4,
    proj8_imgURL5, proj8_imgURL6, proj8_imgURL7, proj8_imgURL8, proj8_imgURL9 } from'./imports';
import {proj8_HimgURL1, proj8_HimgURL2, proj8_HimgURL3, proj8_HimgURL4, proj8_HimgURL5} from './hImgs-imports';

const RevoltWear = () => {


  return (
<>
    <Navbar />
    <ScrollToTop />
    <div className='site__project' id="topPage">
        <div className="site__project-header-imgCollage section__padding">
            <div className='site__project-header-imgCollage-groupA'>
                <img src={proj8_HimgURL1} alt="RevoltWear brand" />
            </div>
            <div className='site__project-header-imgCollage-groupB'>
                 <img src={proj8_HimgURL2} alt="RevoltWear instagram story" />
            </div>
            <div className='site__project-header-imgCollage-groupC'>
                <img src={proj8_HimgURL4} alt="RevoltWear instragram post red" />
                <img src={proj8_HimgURL3} alt="RevoltWear instragram post" />
            </div>
            <div className='site__project-header-imgCollage-groupD'>
                <img src={proj8_HimgURL5} alt="RevoltWear brand" />
            </div>
        </div>

   
        <div className="site__tldr section__padding">
            <div className="site__tldr-groupA" id="projectTitle-UI">
                <h1 className="solid__text-UI" id="projectTitle-mobile-sm">RevoltWear</h1>
            </div>
            <div className="site__tldr-groupA" id="project-UI">
                <p><strong>Deliverables: </strong>Visual Identity</p>
                <p><strong>My Responsibilities: </strong>Design the visual identiy and campaign assets for RevoltWear.</p>
                <p><strong>About: </strong>RevoltWear aims to inspire individuals to break free from conventions, 
                embrace their true potential, and make a mark in the world.</p>
            </div>

            <div className="site__tldr-groupC">
                <p><strong>Completed: </strong>August 2023</p>
                <p><strong>Applications: </strong>Photoshop, Curve, Freeform</p>
                <p><strong>Published On: </strong><a className="site__project-link" href="https://www.behance.net/gallery/177992099/RevoltWear">Behance</a> </p>  
            </div>
        </div>

        
        <div className='site__UX-Design section__padding'>

            <div className='snapshot-img'>
                  <img src={proj8_imgURL1} alt="RevoltWear Logo & Social Media Mockup" />
                  <img src={proj8_imgURL2} alt="RevoltWear Poster & Logo Mockup" />
            </div>
            
            <div className='snapshot-img'>
                <img src={proj8_imgURL3} alt="RevoltWear Poster & Logo Mockup" />
            </div>

            <div className='video__container'>
                <video class="video" loop autoPlay muted playsInline>
                    <source src={proj8_imgURL5} type="video/mp4 "alt="rotating mobile screens animation" />
                    Your browser does not support the video tag.
                </video>
            </div>

            <div className='snapshot-img'>
                <img src={proj8_imgURL4} alt="RevoltWear Poster & Business Cards Mockup" />
            </div>

            <div className='site__2col'>
                <div className='site__2col-groupA'>
                    <img src={proj8_imgURL6} alt="RevoltWear Instagram Post black" />
                    <img src={proj8_imgURL7} alt="RevoltWear Instagram Post green" />

                </div>
                <div className='site__2col-groupB'>
                    <img src={proj8_imgURL8} alt="RevoltWear Instagram Post purple" />
                    <img src={proj8_imgURL9} alt="RevoltWear Instagram Post red" />
                </div>
            </div>
            

            <div className='site__back-to-top'>
                <Link to="#topPage" className="links-nav">Back to top</Link>
            </div>

        </div>

    </div>

    <div className='site__blog-heading'>
    <h1>Discover more.</h1>
    </div>
  
    <Gallery />
</>
  )
}

export default RevoltWear