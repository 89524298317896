import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ScrollToTop from '../components/ScrollToTop';
import  './projects.css';
import  { Navbar } from '../components';

import Blog from '../containers/blog/Blog';
import { proj4_imgURL1, proj4_imgURL2, proj4_imgURL3, proj4_imgURL4, proj4_imgURL12, proj4_imgURL14,
    proj4_imgURL15, proj4_imgURL16 } from './imports';
import {proj4_HimgURL1, proj4_HimgURL2, proj4_HimgURL3, proj4_HimgURL4, proj4_HimgURL5} from './hImgs-imports';

const DarkStar_UI = () => {


  return (
<>
    <Navbar />
    <ScrollToTop />
    <div className='site__project' id="topPage">
        <div className="site__project-header-imgCollage section__padding">
            <div className='site__project-header-imgCollage-groupA'>
                <img src={proj4_HimgURL1} alt="Game UI design, high-fidelity mockup" />
            </div>
            <div className='site__project-header-imgCollage-groupB'>
                 <img src={proj4_HimgURL2} alt="Game iconography sketch process" />
            </div>
            <div className='site__project-header-imgCollage-groupC'>
                <img src={proj4_HimgURL3} alt="Midjourney generated images for game weapon design" />
                <img src={proj4_HimgURL4} alt="Close-up of game iconography" />
            </div>
            <div className='site__project-header-imgCollage-groupD'>
                <img src={proj4_HimgURL5} alt="Close-up of game UI design, Missions screen" />
            </div>
        </div>

   
        <div className="site__tldr section__padding">
            <div className="site__tldr-groupA" id="projectTitle-UI">
                <h1 className="solid__text-UI">Dark Star</h1>
                <div className='project-btn'>
                    <Link to="/Dark-Star-Case-Study" className="links-nav">View Case Study</Link>
                </div>
            </div>
            <div className="site__tldr-groupA" id="project-UI">
                <p><strong>Deliverables:</strong> Inventory, Mission Log, HUD screens</p>
                <p><strong>Project:</strong> Design a UI for a first-person sci-fi horror game </p>
                <p><strong>The Challenge:</strong> No prior experience designing first-person horror games.</p>
                <p><strong>Gaming Community:</strong> This challenge is designed by 'We Can Fix It In UI'</p>
            </div>

            <div className="site__tldr-groupC">
                <p><strong>Duration:</strong> 4 weeks </p>
                <p><strong>Completed:</strong> April 2023</p>
                <p><strong>Applications:</strong> Figma, Photoshop, Procreate</p>
                <p><strong>Figma Files: </strong><a className="site__project-link" href="https://www.figma.com/file/LaszAIYt9qIn9XhFHq0WNq/Dark-Star?node-id=0%3A1&t=5pPHDp2eQ1GsSiAf-1">Wireframes & Moodboard </a></p>        
                <p><strong>Original Project Brief:</strong><a className="site__project-link" href="https://file.notion.so/f/s/6445ff6f-be91-4cd8-bf8a-c39bc537881c/2023_WeCanFixItInUi_UIChallenge_DarkStar.pdf?id=7c2b474f-8838-4ba2-b1c5-354142e8d74b&table=block&spaceId=d9feebfb-568b-4cb3-a9a1-cb1bb347b17f&expirationTimestamp=1681317084941&signature=kEn1qHc6ITnmmQgYoh_uqC_sAa_p34Ui_iZY6-LcgEk&downloadName=2023_WeCanFixItInUi_UIChallenge_DarkStar.pdf" target="_blank"> Dark Star PDF</a></p>
            </div>
        </div>

        
        <div className='site__UX-Design section__padding'>

            <div className='snapshot-img'>
                  <img src={proj4_imgURL4} alt="Game UI design, Iconography sheet" />
                  <img src={proj4_imgURL1} alt="Game UI design, Inventory Screen" />
                  <img src={proj4_imgURL2} alt="Game UI design, Mission Screen" />
            </div>
            
            <div className='site__3col'>
                <div className='site__3col-groupA'>
                <img src={proj4_imgURL14} alt="Animated loop of game UI design, Inventory Screen" />
                </div>
                <div className='site__3col-groupB'>
                  <img src={proj4_imgURL15} alt="Animated loop of game UI design, Mission Screen" />
                </div>
                <div className='site__3col-groupC'>
                <img src={proj4_imgURL12} alt="Animated loop of game UI design, Iconography sketch process" />

                </div>
            </div>

            <div className='snapshot-img'>
                <img src={proj4_imgURL3} alt="Game UI design, in-game HUD elements" />
                <p>Note: Game environment is from ROUTINE. </p>
            </div>
            <div className='snapshot-img' id="project-page-banner">
                <img src={proj4_imgURL16} alt="Iconography banner featuring 3 icons" />
            </div>

            <div className='site__back-to-top'>
                <Link to="#topPage" className="links-nav">Back to top</Link>
            </div>

        </div>

    </div>

    <div className='site__blog-heading'>
    <h1>Discover more.</h1>
    </div>
  
    <Blog />
</>
  )
}

export default DarkStar_UI