import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ScrollToTop from './../components/ScrollToTop';
import  './projects.css';
import  { Navbar } from './../components';

import Blog from '../containers/blog/Blog';
import { proj9_imgURL1, proj9_imgURL2, proj9_imgURL3, proj9_imgURL4, proj9_imgURL5, proj9_imgURL6, 
    proj9_imgURL7, proj9_imgURL8, proj9_imgURL9, proj9_imgURL10, proj9_imgURL11 } from './imports';

const ArtiTicket = () => {


  return (
<>
    <Navbar />
    <ScrollToTop />
    <div className='site__project' id="topPage">
    <div className="site__project-header section__padding" id="home">
     <div className="site__project-header-content" >
        <h1 className="solid__text" id="projectTitle-mobile-sm">ArtiTicket</h1>
        <div className='project-btn'>
            <Link to="/Artiticket" className="site__project-link">Explore UI Art</Link>
        </div>
     </div>

    </div>

   
    <div className="site__tldr section__padding">
        <h3>TLDR;</h3>
        <div className="site__tldr-groupA">
            <p><strong>Deliverables: </strong>App and responsive web design</p>
            <p><strong>Project: </strong>Design a centralized platform to find local/international art events</p>
            <p><strong>The Challenge: </strong>Going beyond the original prompt and make a platform that can be used world-wide</p>
            <p><strong>My Role: </strong>Lead UX Designer, Visual Designer</p>
            <p><strong>Responsibilities: </strong> User research, wireframing, prototyping, visual design, visual identity</p>
        </div>

        <div className="site__tldr-groupB">
            <p><strong>Duration:</strong> April - May 2024</p>
            <p><strong>Completed:</strong> June 2024</p>
            <p><strong>Applications:</strong> Figma, Photoshop</p>
            <p><strong>Prototype: </strong><a className="site__project-link" href="https://www.figma.com/proto/I6ADjHIef1cHWxZMsQJU8x/Coursera-%E2%80%94-Project-02?node-id=151-1242&t=aIEDKhdGjdnbeTAN-1&scaling=scale-down&page-id=151%3A1241&starting-point-node-id=151%3A2223">Figma Prototype </a></p>        
            <p><strong>Case Study:</strong><a className="site__project-link" href="https://docs.google.com/presentation/d/1uByTP6vCYLDeB9LXMIqHtXT2VbDpyIi-tQjXdMrwynk/edit?usp=sharing" target="_blank"> Google Slides</a></p>
            <p><strong>Course: </strong> Coursera, Google UX Design</p>
        </div>
    </div>

    


    <div className='site__UX-Design section__padding'>

        
            <div className='video__container'>
                <video class="video" loop autoPlay muted playsInline>
                    <source src={proj9_imgURL10} type="video/mp4 "alt="rotating mobile screens animation" />
                    Your browser does not support the video tag.
                </video>
            </div>
            

        <div className='site__casestudy section__padding'>
            <div className='site__casestudy-groupA'>
                <div className="site__casestudy-image">
                    <img src={proj9_imgURL3} alt="Bulletpoint 1, project 3: the project for Google's UX Design course" />
                </div>
                <div className='site__casestudy-content'>
                <h3>The Product</h3>
                    <p> Creating an app to provide art enthusiasts and tourists to keep updated with current exhibitions and events.
                    </p>
                </div>
            </div>

            <div className='site__casestudy-groupB'>
                <div className="site__casestudy-image">
                    <img src={proj9_imgURL4} alt="Bulletpoint 2, project 3: the project problem for Google's UX Design course" />
                </div>
                <div className='site__casestudy-content'>
                <h3>The Problem</h3>
                    <p>No streamlined platform exists for searching local and international events, exhibition and museums with prior knowledge of art
                        communities or locations. There is also no easy way to stay connected to local art scenes.
                    </p>
                </div>
            </div>

            <div className='site__casestudy-groupC'>
                <div className="site__casestudy-image">
                    <img src={proj9_imgURL5} alt="Bulletpoint 3, project 3: the project goal for Google's UX Design course" />
                </div>
                <div className='site__casestudy-content'>
                    <h3>The Goal</h3>
                    <p>Allow users to actively stay involved in local or internation art events, exhibitions and museums.</p>
                </div>
            </div>
        </div>

            <div className='site__UX-Design-content'>
                    <h3>User Research Summary</h3>
                        <p>Applying the knowledge originally gained from the six modules of the Google UX Design course, 
                            I leveraged both new and existing skills to complete the final project: ArtiTicket.</p>

                        <p>I wanted to take things further than the original prompt by creating a centralized platform where 
                            people can find local and international events, helping them stay more connected with the art scene
                            and enabling those who want to be more involved.
                        </p>

                        <p><strong>Original project prompt: </strong>
                        <i>Design an app and responsive website for a public art museum to advertise exhibitions and events,
                            provide museum information to patrons, and enable patrons to schedule events.</i></p>
                </div>


        <div className='site__UX-Design-content'>
            <h3>User Pain Points</h3>
                <p><strong>Staying Connected </strong><br></br>
                It's challenging to discover and keep track of local art events and exhibitions.</p>
          
                <p><strong>Diverse Audiences </strong><br></br>
                 A lack of accessibiity and inclusivity of under represented groups are in the arts, including
                 people with disabilities.</p>
              
                <p><strong>Accessibility</strong><br></br>
                There is a lack of awareness and accommodations for individuals with disabilites in the arts and cultural sector.</p>
          
        </div>

      
        <div className='site__UX-Design-content'>
                <h3>User Journey Map</h3>
                <p>Mapping out the flow for Jama's user journey revealed the importance of creating a product
                    for users who need accessible information displayed prominently. </p>
        </div>

        <div className='site__2col'>
            <div className='site__2col-groupA'>
                <img src={proj9_imgURL6} alt="User Journey Map for Jamal's persona" />
            </div>
            <div className='site__2col-groupB'>
                 <img src={proj9_imgURL11} alt="User Journey Map for Maya's persona" />
            </div>
        </div>

        <div className='site__UX-Design-content'>
                <h3>Paper Wireframes</h3>
                <p>Drafting a few iterations of multiple screens to ensure elements 
                    in the wireframes are well-suited to address user pain points.</p>

                <p>Featured screens are the Explore and Exhibit page.</p>
        </div>

        <div className='site__2col'>
            <div className='site__2col-groupA'>
                <img src={proj9_imgURL7} alt="paper wireframe of the exhibit page for mobile design" />
            </div>
            <div className='site__2col-groupB'>
                 <img src={proj9_imgURL8} alt="paper wireframe of the explore page for mobile design" />
            </div>
        </div>


        <div className='site__UX-Design-content'>
                <h3>Usability Study Findings</h3>
                <p>Two usability studies were conducted. The first one to help guide the designs from wireframes to mockups.
                    The second used the high-fidelity prototype and revealed what aspects of the mockups needs refining.
                </p>
        </div>

        <div className='site__2col'>
            <div className='site__2col-groupA'>
                <h4>Round 1 Findings</h4>
                    <p>1. The difference between the Visit and Explore tab was unclear</p>
                    <p>2. Icons were unclear and needed labels</p>
                    <p>3. Participants are okay with sharing the location</p>
            </div>
            <div className='site__2col-groupB'>
                <h4>Round 2 Findings</h4>
                    <p>1. More information about location, hours, dates, etc were not easily accessible</p>
                    <p>2. More confirmation is needed on where purchased tickets are stored and how to save them</p>
            </div>
        </div>

        <div className='site__UX-Design-content'>
            <h3>Accessibility Considerations</h3>
          
                    <p><strong>High Contrast </strong><br></br>
                    Since the app's primary design is in Dark Mode, the usage of high contrast between elements and the background are used.</p>
           
                    <p><strong>Visual Hierarchy </strong><br></br>
                    Clear visual hierarchy is used to help organize content and for screen readers to easily read the design in the correct, intentional order.</p>
           
                    <p><strong>Iconography</strong><br></br>
                    Icons are utilized throughout the design intentionaly design for the brand and are also industry-standard.</p>
            
        </div>


        <div className='snapshot-img'>
             <img src={proj9_imgURL9} alt="High-fidelity mobile design, mocked-up showcasing the use of industry-standard icons, visual hierarchy, and high colour contrast" />
        </div>
     
        
        <div className='site__UI-Design-content'>
                <h3>Refining the Design</h3>
        </div>

        <div className='snapshot-img'>
             <img src={proj9_imgURL1} alt="High-fidelity mockup of the mobile home page" />
        </div>

        <div className='snapshot-img'>
             <img src={proj9_imgURL2} alt="High-fidelity mockup of the mobile and responsive website design" />
        </div>

     
        <div className='site__UX-Design-content'>
                <h3>Going Forward</h3>
                <p><strong>Impact: </strong>All participants didn't expect to find their purchased ticket accessible through
                the app and were pleasantly surprised when they found out they could.
                </p>
                <p><strong>What I Learned: </strong>Having completed my third project in Coursera's Google UX Design course, 
                I’ve realized the need to enhance purchase confirmation and provide more frequent user reassurance.
                </p>
        </div>


        <div className='site__UX-Design-content'>
            <h3>Next Steps</h3>
            
            <p><strong>Step 01</strong></p>
                <p>Another round of user testing on both the app and responsive website design.</p>
                <p>Do user's find the design consistent on both platforms? Could the user experienpmnce on both platforms be improved?
                Where does it excel? etc.</p>
               
            <p><strong>Step 02</strong></p>
                <p>Include more under represented groups in user testing.</p>
                <p>Do these users find there is enough diversity in cultural representation, beliefs, artists?</p>

            <p><strong>Step 03</strong></p>
                <p>Does the current accessibiity features need improvement?</p>
                <p>What is done well? Where could there be improvement?</p>
              
        </div>

        <div className='site__back-to-top'>
            <Link to="#topPage" className="links-nav">Back to top</Link>
        </div>

    </div>

    

    </div>

    <div className='site__blog-heading'>
    <h1>Discover more.</h1>
    </div>
  
    <Blog />
</>
  )
}

export default ArtiTicket