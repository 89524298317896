import React from 'react';
// import { Navagate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import { Tidbits, Blog, Header, Recent, MasonryHome  } from './containers';
import { Social, Navbar } from './components';
import './App.css';


const App = () => {

  return (
    <div className="App">
      {/* <div class="vl"></div> */}
      <div className='site__header-img-BG'>
        <Navbar />
        <Header />
      </div>

      

      {/* <div className="site__gallery-heading site__gallery-container_groupA" id="recent-container">
          <h1>Recent work.</h1>
          <p>A collection of work, past and present</p>
      </div> */}
      <Recent />

      {/* <div className='site__masonryHome-container  section__padding'>
        <div className='site__2col'>
          <div className='site__2col-groupA'>
            <div className='site__title'>
              <h2>Visual and interactive design.</h2>
              <p>Utilizing a computer science background as the connector between the alliance of graphic and UX/UI design.</p>
            </div>
          </div>

          <div className='site__2col-groupB'>
            <div className='project-btn-container'>
              <div className='project-btn'>
                <Link to="/gd" className="links-nav">
                  Graphic Design 
                  <div className='svg__container basline'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                    </svg>
                  </div>
                </Link>
              </div>
              <div className='project-btn'>
                <Link to="/ux-ui" className="links-nav">
                  UX / UI Design 
                  <div className='svg__container basline'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
       <div className="view-desktop"><MasonryHome /></div>
      </div> */}

      

      

      

      {/* <Tidbits /> */}
      
 
      
    </div>
  )
}

export default App