import React from 'react';
import './brand.css';
import { ADAA2016, ADAA2019, SuicideSheep2019, elvtr, udemy, paintable, GoogleUX } from './imports';

const Brand = () => {
  return (
    <div className="site__brand section__padding">
        <div>
        <a href="https://www.credly.com/badges/34bdd0dc-3833-43a6-848d-144d8f237902/public_url" target="_blank"><img src={GoogleUX} alt="Google UX Design course completion certificate badge" /></a>
      </div>
      <div>
      <a href="https://www.credly.com/badges/3287261d-653f-4aa4-85ce-c282cdab14d9/public_url" target="_blank"><img src={ADAA2016} alt="Adobe Design Achievement Awards Semi Finalist" /></a>
      </div>
      <div>
      <a href="https://www.credly.com/badges/645a6b80-698d-43e1-8a72-bab30877968e/public_url" target="_blank"><img id="ADAA" src={ADAA2019} alt="Adobe Design Achievement Awards 2019" /></a>
      </div>
      <div>
        <img src={SuicideSheep2019} alt="Suicide Sheep Contest Entry" />
      </div>
      <div>
        <a href="https://elvtr.com/certificate/e745609c19d7d1810502e083f20a3da5" target="_blank"><img src={elvtr} alt="elvtr UI/UX course completion certificate link" /></a>
      </div>
      <div>
        <a href="https://udemy-certificate.s3.amazonaws.com/pdf/UC-7fa91dea-7826-4df4-86aa-7415ad756fe7.pdf" target="_blank"><img src={udemy} alt="Udemy Character Design course completion certificate badge" /></a>
      </div>
      <div>
        <a href="https://drive.google.com/file/d/1ppsD35vKRujHBw00-hlfKngAcx5HEHlL/view" target="_blank"><img id="paintable" src={paintable} alt="Illustration from Imagination course completion certificate" /></a>
      </div>

    </div>
  )
}

export default Brand