import React from 'react';
import { Photo } from '../../components';
import { photo01, photo02, photo03, photo04, photo05} from'./imports';
import './gallery.css';

const Gallery = () => {
  return (
    <>
    <div className="site__gallery section__padding" id="gallery">
    
      <div className="site__gallery-container">
       
        <div className="site__gallery-container_groupB">
          <Photo num="01" imgUrl={photo01} title="Infinitea" type="Product Design" URL="/Infinitea"/>
          {/* <Photo num="02" imgUrl={photo02} title="I am Woman" type="Editorial Design" URL="https://www.behance.net/gallery/138346909/I-AM-WOMAN"/> */}
          <Photo num="02" imgUrl={photo04} title="Minus The" type="Logo Design" URL="/Minus-The"/>
          <Photo num="03" imgUrl={photo05} title="RevoltWear" type="Visual Identity"  URL="/RevoltWear"/>
          {/* <Photo num="04" imgUrl={photo03} title="Lettie & Co" type="Logo Design" URL="https://www.behance.net/gallery/167343099/Lettie-Co"/> */}
        </div>
      </div>
    </div>
        </>
  )
}

export default Gallery