import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ScrollToTop from './../components/ScrollToTop';
import  './projects.css';
import  { Navbar } from './../components';

import Blog from '../containers/blog/Blog';
import { proj4_imgURL1, proj4_imgURL2, proj4_imgURL3, proj4_imgURL4, proj4_imgURL5, proj4_imgURL6, 
    proj4_imgURL7, proj4_imgURL10, proj4_imgURL11, proj4_imgURL12, proj4_imgURL13, proj4_imgURL14,
    proj4_imgURL15, proj4_imgURL16, proj4_imgURL17 } from './imports';

const DarkStar = () => {


  return (
<>
    <Navbar />
    <ScrollToTop />
    <div className='site__project' id="topPage">
    <div className="site__project-header section__padding" id="home">
     <div className="site__project-header-content" >
        <h1 className="solid__text">Dark Star</h1>
        <div className='project-btn'>
            <Link to="/Dark-Star" className="site__project-link">Explore UI Art</Link>
        </div>
     </div>

    </div>

   
    <div className="site__tldr section__padding">
        <h3>TLDR;</h3>
        <div className="site__tldr-groupA">
            <p><strong>Deliverables:</strong> Inventory, Mission Log, HUD screens</p>
            <p><strong>Project:</strong> Design a UI for a first-person sci-fi horror game </p>
            <p><strong>The Challenge:</strong> No prior experience designing first-person horror games.</p>
            <p><strong>Gaming Community:</strong> This challenge is designed by 'We Can Fix It In UI'</p>
        </div>

        <div className="site__tldr-groupB">
            <p><strong>Duration:</strong> 4 weeks </p>
            <p><strong>Completed:</strong> April 2023</p>
            <p><strong>Applications:</strong> Figma, Photoshop, Procreate</p>
            <p><strong>Figma Files: </strong><a className="site__project-link" href="https://www.figma.com/file/LaszAIYt9qIn9XhFHq0WNq/Dark-Star?node-id=0%3A1&t=5pPHDp2eQ1GsSiAf-1">Wireframes & Moodboard </a></p>        
            <p><strong>Original Project Brief:</strong><a className="site__project-link" href="https://file.notion.so/f/s/6445ff6f-be91-4cd8-bf8a-c39bc537881c/2023_WeCanFixItInUi_UIChallenge_DarkStar.pdf?id=7c2b474f-8838-4ba2-b1c5-354142e8d74b&table=block&spaceId=d9feebfb-568b-4cb3-a9a1-cb1bb347b17f&expirationTimestamp=1681317084941&signature=kEn1qHc6ITnmmQgYoh_uqC_sAa_p34Ui_iZY6-LcgEk&downloadName=2023_WeCanFixItInUi_UIChallenge_DarkStar.pdf" target="_blank"> Dark Star PDF</a></p>
            <p><strong>UI Art: </strong><Link to="/Dark-Star" className="site__project-link">Explore UI Art</Link></p>
        
        </div>
    </div>

    


    <div className='site__UX-Design section__padding'>

        
        <div className='snapshot-img'>
             <img src={proj4_imgURL17} alt="Game UI design, high-fidelity mockup" />
        </div>

            <div className='site__UX-Design-content'>
                    <h3>The Somerville Expedition</h3>
                    <p>The UI challenge, "Dark Star," was inspired by the 'We Can Fix It In UI' community. 
                        Designers were tasked with creating UI screens for a sci-fi horror game set on a research 
                        vessel in space called "The Somerville Expedition." The game pits players against an alien threat,
                         prompting them to escape while uncovering the ship's mysteries. The challenge aimed for top-notch 
                         user experience, allowing designers to explore various design levels, from sketches to high-fidelity
                          designs. For me, it was a chance to refine skills and step into the gaming industry.</p>

                          <p>Designing for first-person and horror video games was uncharted territory for me before Dark Star. 
                        Immersion, atmosphere, and user interface took center stage in this novel challenge. 
                        Crafting fear and tension was crucial for engagement, while the UI needed to be intuitive yet fit 
                        the game's aesthetic. Despite the daunting nature of the task, I embraced it, gaining valuable 
                        experience in a new gaming genre.</p>
                </div>

        <div className='snapshot-img'>
             <img src={proj4_imgURL7} alt="Moodboard of sci-fi game elements such as HUD, iconography, game UI design" />
        </div>

        <div className='site__UX-Design-content'>
                <h3>Moodboard and Sketches</h3>
                <p>To tackle this challenge, I delved into horror game UI by curating a mood board from Interface In Game. 
                    Despite limited experience in this genre, I sketched designs leveraging UX/UI concepts from a recent 
                    game development course. Researching top-rated horror games, I gathered UI images for inspiration and 
                    understanding fundamental user experiences. With these references, I began sketching various designs, 
                    navigating trial and error to translate ideas effectively while prioritizing user experience and content 
                    organization.</p>
        </div>

        <div className='snapshot-img'>
             <img src={proj4_imgURL13} alt="Paper wireframes of game screens for inventory, missions, HUD" />
        </div>


        <div className='site__UX-Design-content'>
                <h3>Wireframes</h3>
                <p>After completing sketches, I transitioned them to Figma for Dark Star's design. However, 
                    similarities to another case study initially disheartened me. Seeking guidance from an industry 
                    professional via ADPList, I received valuable feedback on low-fidelity wireframes, which highlighted 
                    the gap between my work and industry standards. Recognizing the room for improvement inspired me to 
                    elevate my designs and renewed my enthusiasm for the high-fidelity stage. </p>
        </div>

        <div className='snapshot-img'>
             <img src={proj4_imgURL6} alt="Low-fidelity design of the Mission, Inventory, HUD, Crosshair, weapon selection UI design in Figma" />
             <p>First iteration of wireframes</p>

             <img src={proj4_imgURL10} alt="Revised design of the low-fidelity paper wireframes in Figma" />
             <p>Final iteration of wireframes</p>
        </div>

        <div className='site__2col'>
            <div className='site__2col-groupA'>
                <img src={proj4_imgURL5} alt="Midjourney generated sci-fi weapons for inspiration" />
                <p>Midjourney design</p>
            </div>
            <div className='site__2col-groupB'>
            <img src={proj4_imgURL12} alt="GIF of iconography sketch process" />
            </div>
        </div>
            <div className='site__UI-Design-content'>
                        
                        <h3>High Fidelity Design</h3>
                        <p>Seeking guidance from my mentor, I delved into textures and iconography for 
                            high-fidelity designs in gaming. When requesting weapons from Midjourney for a sci-fi 
                            horror game, a miscommunication led to literal alien-like designs instead of fiery weaponry. 
                            After refining some designs, I dove into creating the UI. Utilizing Unsplash for royalty-free 
                            visuals, I curated textures and patterns that matched the game's aesthetic. Iconography 
                            posed a challenge; after much research and procrastination, I found my design path. </p>
            </div>


        <div className='snapshot-img'>
            <img src={proj4_imgURL4} alt="Game UI design, Iconography sheet" />
            <img src={proj4_imgURL11} alt="Game iconography sketch process" />
         </div>

        
        <div className='site__UI-Design-content'>
                <h3>01 - Inventory</h3>
                <p>It's worth noting that I have always been drawn to this particular screen, and it's usually the first one 
                    I work on. This may be because it offers ample opportunities to incorporate various design elements like 
                    typography, illustration, iconography, color theory, and so on.</p>

                <p>As Dark Star belongs to the sci-fi horror genre, I aimed to create a UI design that would provide a more 
                    realistic and immersive experience for players. To achieve this, I decided to limit the number of items 
                    that players could hold, empowering them to strategically choose which weapons, armor, and key items were 
                    essential for their gameplay. Allowing players to accumulate an excessive amount of med kits, bullets, 
                    or weapons would make the exploration of the research vessel feel safer, whereas the possibility of 
                    running out of ammo or health during a fight would increase the level of uncertainty and fear, which 
                    aligns with the core elements of a horror game.</p><br />

                <p><strong>Designer Notes:</strong></p>
                <p>— Pressing L1 or L2 will instantly take the player to a different section (e.g. mission, inventory, system).</p>
                <p>— Using R1 and R2 will let the player scroll through all available screens within the menu.</p>
                <p>— A designated area will allow the player to upgrade their weapons, while also providing storage options if needed. Players can only hold a maximum of 4 weapons at any given time.</p>
                <p>— The player can also carry a maximum of 8 misc and consumable items at a time.</p>
                <p>— In the Inventory Menu as well as during gameplay, players can switch between different ammo types.</p>
        </div>

        <div className='snapshot-img'>
             <img src={proj4_imgURL1} alt="Game UI design, Inventory Screen" />
        </div>

        <div className='snapshot-img' id="sm-gif">
             <img src={proj4_imgURL14} alt="GIF of Game UI design, Inventory Screen process" />
        </div>

       
        <div className='site__UI-Design-content'>
                <h3>02 - Mission Log</h3>
                <p>This is my initial venture into designing a mission log, and I found that the process was much more intuitive 
                    in terms of element placement and data organization. The design prioritizes a neat and structured appearance 
                    that mirrors the arrangement of computer files in Finder (for Mac OS users) or File Explorer (for PC users), 
                    with a column-based layout. The first column consists of folders, while the second displays their respective 
                    contents.</p>
        </div>

        <div className='snapshot-img'>
             <img src={proj4_imgURL2} alt="Game UI design, Mission Screen" />
        </div>
        <div className='snapshot-img' id="sm-gif">
             <img src={proj4_imgURL15} alt="GIF of Game UI design, Mission Screen process" />
        </div>


        

        <div className='site__UI-Design-content'>
                <h3>03 - HUD</h3>
                <p>Crafting the HUD was an interesting process. It presented a distinct challenge as the UI had to be unobtrusive 
                    yet informative enough to guide players as needed. Additionally, designing the wireframe in isolation, without 
                    visual elements, posed another hurdle. As someone transitioning from the Marketing Industry, I'm accustomed to 
                    filling negative space, which initially felt uncomfortable. However, I recognize that the UI will come to life 
                    when integrated into actual gameplay, alleviating unease.</p><br />


                <p><strong>Designer Notes:</strong></p>
                <p>— When players use consumables like health, the design includes a cool-down meter or duration display to track the skill's progress.</p>
    
        </div>

        <div className='snapshot-img'>
             <img src={proj4_imgURL3} alt="Game UI design, in-game HUD elements" />
             <p>Note: Game environment is from ROUTINE. </p>
        </div>
     
       


        <div className='site__UI-Design-content'>
                <h3>Overview & Going Further</h3>
                <p>This challenge provided a fresh outlook on game UI design, emphasizing the need for a solid grasp of design, engineering, 
                    and player engagement. Balancing UX/UI while avoiding attention blindness, where players tune out overloaded information, 
                    was key. It's been a significant learning curve, highlighting the responsibilities of a UI/UX designer in gaming.</p>

                <p>Comparing my first case study to this, my skills in UI/UX for gaming have notably advanced. Moving beyond 2D designs to 
                    incorporate textures and 3D elements was a leap, challenging my comfort zone. While satisfied, I aim to elevate future 
                    designs by infusing a more distinct 3D essence, moving away from the somewhat flat feel of the current design.</p>

        </div>

        <div className='snapshot-img' id="project-page-banner">
             <img src={proj4_imgURL16} alt="Iconography banner featuring 3 icons" />
        </div>

        <div className='site__back-to-top'>
            <Link to="#topPage" className="links-nav">Back to top</Link>
        </div>

    </div>

    

    </div>

    <div className='site__blog-heading'>
    <h1>Discover more.</h1>
    </div>
  
    <Blog />
</>
  )
}

export default DarkStar