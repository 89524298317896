import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ScrollToTop from '../components/ScrollToTop';
import  './projects.css';
import  { Navbar } from '../components';

import Blog from '../containers/blog/Blog';
import { proj3_imgURL4, proj3_imgURL5, proj3_imgURL6,
    proj3_imgURL8, proj3_imgURL9, proj3_imgURL10 } from'./imports';
import {proj3_HimgURL1, proj3_HimgURL2, proj3_HimgURL3, proj3_HimgURL4, proj3_HimgURL5} from './hImgs-imports';

const BOTWicons_UI = () => {


  return (
<>
    <Navbar />
    <ScrollToTop />
    <div className='site__project' id="topPage">
        <div className="site__project-header-imgCollage section__padding">
            <div className='site__project-header-imgCollage-groupA'>
                <img src={proj3_HimgURL1} alt="icon sketches ipad" />
            </div>
            <div className='site__project-header-imgCollage-groupB'>
                 <img src={proj3_HimgURL2} alt="botw icons" />
            </div>
            <div className='site__project-header-imgCollage-groupC'>
                <img src={proj3_HimgURL3} alt="botw icons" />
                <img id="med-gif" src={proj3_HimgURL4} alt="botw icon gif" />
            </div>
            <div className='site__project-header-imgCollage-groupD'>
                <img src={proj3_HimgURL5} alt="botw icons" />
            </div>
        </div>

   
        <div className="site__tldr section__padding">
            <div className="site__tldr-groupA" id="projectTitle-UI">
                <h1 className="solid__text-UI">Botw Icons</h1>
                <div className='project-btn'>
                    <Link to="/Breath-of-the-Wild-icons-case-study" className="links-nav">View Case Study</Link>
                </div>
            </div>
            <div className="site__tldr-groupA" id="project-UI">
                <p><strong>Deliverables:</strong> Game Icons</p>
                <p><strong>Project:</strong> Personal project to practice illustration and creating icons used in video games</p>
                <p><strong>My Responsibilities:</strong> Illustrating Breath of the Wild icons</p>
                <p><strong>The Challenge:</strong> Re-creating recognizable icons from a respected video game</p>
            </div>

            <div className="site__tldr-groupC">
                <p><strong>Completed:</strong> October 2022</p>
                <p><strong>Applications:</strong> Procreate</p>
                <p><strong>Published On: </strong><a className="site__project-link" href="https://www.artstation.com/artwork/2qE0ZA">Artstation </a> </p>  
            </div>
        </div>

        
        <div className='site__UX-Design section__padding'>

            <div className='snapshot-img'>
                  <img src={proj3_imgURL4} alt="breath of the wild icon sketch" />
                  <img src={proj3_imgURL5} alt="breath of the wild icon sketch ipad" />
                  <img src={proj3_imgURL8} alt="sbreath of the wild breakdown process, figma, orcarina of time" />
            </div>
            
            <div className='site__2col'>
                <div className='site__2col-groupA'>
                <img src={proj3_imgURL9} alt="breath of the wild icon, spirit orb" />
                </div>
                <div className='site__2col-groupB'>
                  <img src={proj3_imgURL10} alt="breath of the wild icon, daruk's protection" />
                </div>
            </div>

            <div className='snapshot-img'>
                <img src={proj3_imgURL6} alt="breath of the wild all icons" />
            </div>
            

            <div className='site__back-to-top'>
                <Link to="#topPage" className="links-nav">Back to top</Link>
            </div>

        </div>

    </div>

    <div className='site__blog-heading'>
    <h1>Discover more.</h1>
    </div>
  
    <Blog />
</>
  )
}

export default BOTWicons_UI