import React from 'react';
import { Card } from '../../components';
import './tidbits.css';

const Tidbits = () => {
  return (
    <>
    <div className="site__tidbits section__padding" id="tidbits">
    
      <div className="site__tidbits-container">
        <div className="site__tidbits-heading site__tidbits-container_groupA">
          <p>For those who are curious</p>
          {/* <h3>Interesting factoids.</h3> */}
          <h1>Completed & active quests.</h1>
        </div>
        <div className="site__tidbits-container_groupB">
          <Card title="UX/UI for Gaming by Ivy Sang — from Bungie" type="Certificate" URL="https://drive.google.com/file/d/1v1Gw5ywAFFh9U2IUEwTIzZPvjMWSRXx3/view?usp=share_link"/>
          <Card title="ADAA Semifinalist — Illustration" type="Certificate / Badge" URL="https://www.credly.com/badges/3287261d-653f-4aa4-85ce-c282cdab14d9/public_url"/>
          <Card title="ADPList Product-Led Growth Series 2" type="Certificate" URL="https://drive.google.com/file/d/1vFNCG7wwa8RO8NjRKEtrtmztRpyNq5sE/view?usp=share_link"/>
          <Card title="UX Foundations: Style Guides & Design Systems" type="Certificate" URL="https://drive.google.com/file/d/12aVnsVFIdrHVCUfco8LcQiEpJLrnVV7H/view?usp=share_link"/>
          <Card title="Hogwarts Legacy vs Open World Games" type="UX Design Discussion" URL="/Hogwarts-Legacy-vs-Open-World-Games"/>
        
        </div>
      </div>
    </div>
        </>
  )
}

export default Tidbits