import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ScrollToTop from '../components/ScrollToTop';
import  './projects.css';
import  { Navbar } from '../components';

import Blog from '../containers/blog/Blog';
import { proj9_imgURL1, proj9_imgURL2, proj9_imgURL9, proj9_imgURL10, proj9_imgURL11 } from './imports';
import {proj9_HimgURL1, proj9_HimgURL2, proj9_HimgURL3, proj9_HimgURL4, proj9_HimgURL5} from './hImgs-imports';

const ArtiTicket_UI = () => {


  return (
<>
    <Navbar />
    <ScrollToTop />
    <div className='site__project' id="topPage">
        <div className="site__project-header-imgCollage section__padding">
            <div className='site__project-header-imgCollage-groupA'>
                <img src={proj9_HimgURL1} alt="Game UI design, high-fidelity mockup" />
            </div>
            <div className='site__project-header-imgCollage-groupB'>
                 <img src={proj9_HimgURL2} alt="Game iconography sketch process" />
            </div>
            <div className='site__project-header-imgCollage-groupC'>
                <img src={proj9_HimgURL3} alt="Midjourney generated images for game weapon design" />
                <img src={proj9_HimgURL4} alt="Close-up of game iconography" />
            </div>
            <div className='site__project-header-imgCollage-groupD'>
                <img src={proj9_HimgURL5} alt="Close-up of game UI design, Missions screen" />
            </div>
        </div>

   
        <div className="site__tldr section__padding">
            <div className="site__tldr-groupA" id="projectTitle-UI">
                <h1 className="solid__text-UI" id="projectTitle-mobile-sm">ArtiTicket</h1>
                <div className='project-btn'>
                    <Link to="/Artiticket-Case-Study" className="links-nav">View Case Study</Link>
                </div>
            </div>
            <div className="site__tldr-groupA" id="project-UI">
                <p><strong>Deliverables: </strong>App and responsive web design</p>
                <p><strong>Project: </strong>Design a centralized platform to find local/international art events</p>
                <p><strong>The Challenge: </strong>Going beyond the original prompt and make a platform that can be used world-wide</p>
                <p><strong>My Role: </strong>Lead UX Designer, Visual Designer</p>
                <p><strong>Responsibilities: </strong> User research, wireframing, prototyping, visual design, visual identity</p>
            </div>

            <div className="site__tldr-groupC">
                <p><strong>Duration:</strong> April - May 2024</p>
                <p><strong>Completed:</strong> June 2024</p>
                <p><strong>Applications:</strong> Figma, Photoshop</p>
                <p><strong>Prototype: </strong><a className="site__project-link" href="https://www.figma.com/proto/I6ADjHIef1cHWxZMsQJU8x/Coursera-%E2%80%94-Project-02?node-id=151-1242&t=aIEDKhdGjdnbeTAN-1&scaling=scale-down&page-id=151%3A1241&starting-point-node-id=151%3A2223">Figma Prototype </a></p>        
                <p><strong>Case Study:</strong><a className="site__project-link" href="https://docs.google.com/presentation/d/1uByTP6vCYLDeB9LXMIqHtXT2VbDpyIi-tQjXdMrwynk/edit?usp=sharing" target="_blank"> Google Slides</a></p>
                <p><strong>Course: </strong> Coursera, Google UX Design</p>
            </div>
        </div>

        
        <div className='site__UX-Design section__padding'>

            <div className='snapshot-img'>
                  <img src={proj9_imgURL1} alt="iPhone displayed on crate showing mobile homescreen" />
            </div>

            <div className='video__container'>
                <video class="video" loop autoPlay muted playsInline>
                    <source src={proj9_imgURL10} type="video/mp4 "alt="rotating mobile screens animation" />
                    Your browser does not support the video tag.
                </video>
            </div>
            
            <div className='snapshot-img'>
                  <img src={proj9_imgURL9} alt="close-up of two iPhones showing the exhibition and explore page" />
            </div>

            <div className='snapshot-img'>
                <img src={proj9_imgURL2} alt="comparison mockups of mobile and desktop homescreen" />
            </div>
    

            <div className='site__back-to-top'>
                <Link to="#topPage" className="links-nav">Back to top</Link>
            </div>

        </div>

    </div>

    <div className='site__blog-heading'>
    <h1>Discover more.</h1>
    </div>
  
    <Blog />
</>
  )
}

export default ArtiTicket_UI