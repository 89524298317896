import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ScrollToTop from '../components/ScrollToTop';
import  './projects.css';
import  { Navbar } from '../components';

import Gallery from '../containers/gallery/Gallery';
import { proj6_imgURL1, proj6_imgURL2, proj6_imgURL3, proj6_imgURL4,
    proj6_imgURL5, proj6_imgURL6 } from'./imports';
import {proj6_HimgURL1, proj6_HimgURL2, proj6_HimgURL3, proj6_HimgURL4, proj6_HimgURL5} from './hImgs-imports';

const Minus = () => {


  return (
<>
    <Navbar />
    <ScrollToTop />
    <div className='site__project' id="topPage">
        <div className="site__project-header-imgCollage section__padding">
            <div className='site__project-header-imgCollage-groupA'>
                <img src={proj6_HimgURL1} alt="Minus brand guide cropped" />
            </div>
            <div className='site__project-header-imgCollage-groupB'>
                 <img src={proj6_HimgURL2} alt="Minus brand" />
            </div>
            <div className='site__project-header-imgCollage-groupC'>
                <img src={proj6_HimgURL3} alt="Minus brand" />
                <img src={proj6_HimgURL4} alt="Minus logo" />
            </div>
            <div className='site__project-header-imgCollage-groupD'>
                <img src={proj6_HimgURL5} alt="Minus brand guide cropped" />
            </div>
        </div>

   
        <div className="site__tldr section__padding">
            <div className="site__tldr-groupA" id="projectTitle-UI">
                <h1 className="solid__text-UI">Minus The</h1>
            </div>
            <div className="site__tldr-groupA" id="project-UI">
                <p><strong>Deliverables:</strong> Logo & Style Guide</p>
                <p><strong>Project:</strong> Community challenge hosted on Discord</p>
                <p><strong>My Responsibilities:</strong> Create a cohesive yet versatile logo for 
                the brand Minus The that can adapt to different products being showcased.</p>
                <p><strong>Additions:</strong> Despite the challenge's requirement for only a logo, 
                I decided to create a comprehensive style guide to further developing the brand.</p>
            </div>

            <div className="site__tldr-groupC">
                <p><strong>Logo Completed:</strong> Jan 2023</p>
                <p><strong>Style Guide Completed:</strong> July 2023</p>
                <p><strong>Applications:</strong> Illustrator, Photoshop</p>
                
                {/* <p><strong>Published On: </strong><a className="site__project-link" href="https://www.artstation.com/artwork/2qE0ZA">Artstation </a> </p>   */}
            </div>
        </div>

        
        <div className='site__UX-Design section__padding'>

            <div className='snapshot-img'>
                  <img src={proj6_imgURL1} alt="Minus brand guide" />
            </div>
            
            <div className='site__2col'>
                <div className='site__2col-groupA'>
                  <img src={proj6_imgURL2} alt="Minus brand guide intro" />
                </div>
                <div className='site__2col-groupB'>
                <img src={proj6_imgURL3} alt="Minus brand guide cover" />
                </div>
            </div>
            
            <div className='site__2col'>
                <div className='site__2col-groupA'>
                <img src={proj6_imgURL4} alt="Minus logo GIF" />
                </div>
                <div className='site__2col-groupB'>
                  <img src={proj6_imgURL5} alt="Minus Logo grid display" />
                </div>
            </div>

            <div className='snapshot-img'>
                <img src={proj6_imgURL6} alt="Minus business cards mockup" />
            </div>

            <div className='site__back-to-top'>
                <Link to="#topPage" className="links-nav">Back to top</Link>
            </div>

        </div>

    </div>

    <div className='site__blog-heading'>
    <h1>Discover more.</h1>
    </div>
  
    <Gallery />
</>
  )
}

export default Minus